import Vue from "vue";
import VueI18n from "vue-i18n";
import header from "./temp/views_2024-6-25-609918.json";
import footer from "./temp/views_2024-6-26-772326.json";
import layout from "./temp/views_2024-6-26-311840.json";
import ContactHongpu from "./temp/views_2024-6-26-660888.json";
import homepage from "./temp/views_2024-6-28-877472.json";
import Product from "./temp/views_2024-6-27-020576.json";
import CrossChecking from "./temp/views_2024-6-27-463767.json";
import ExploreHongpu from "./temp/views_2024-6-27-425548.json";
import JASolar from "./temp/views_2024-6-28-281488.json";
import JoinHongpu from "./temp/views_2024-6-28-783135.json";
import News from "./temp/views_2024-6-28-900941.json";
import Construction from "./temp/views_2024-6-28-075095.json";
import SerialRework from "./temp/views_2024-7-1-269188.json";
import Busbar from "./temp/views_2024-7-1-669212.json";
import Filming from "./temp/views_2024-7-1-358282.json";
import CCIC from "./temp/views_2024-7-1-722575.json";
import Finallnspection from "./temp/views_2024-7-1-073596.json";
import Stringer from "./temp/views_2024-7-1-600418.json";
import IndustrySuite from "./temp/views_2024-7-1-869702.json";
import PowerForecasting from "./temp/views_2024-7-1-452973.json";
import ModelTraining from "./temp/views_2024-7-1-191217.json";
import AIPlatform from "./temp/views_2024-7-1-852436.json";
import Solution from "./temp/views_2024-7-3-104455.json";
import Router from "./temp/views_router.json";
import store from "@/store";

// 引入element-ui语言包
import elementEn from "element-ui/lib/locale/lang/en";
import elementZh from "element-ui/lib/locale/lang/zh-CN";
// 下面不可少的两个配置【参考官网 按需加载里定制 i18n】
import ElementLocale from "element-ui/lib/locale";
ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.use(VueI18n); // 全局挂载
// 合并变量
let merged = {
  zh: {
    ...Router["zh"],
    ...elementZh,
    ...header["zh"],
    ...layout["zh"],
    ...footer["zh"],
    ...ContactHongpu["zh"],
    ...homepage["zh"],
    ...Product["zh"],
    ...CrossChecking["zh"],
    ...ExploreHongpu["zh"],
    ...JASolar["zh"],
    ...JoinHongpu["zh"],
    ...News["zh"],
    ...Construction["zh"],
    ...SerialRework["zh"],
    ...Busbar["zh"],
    ...Filming["zh"],
    ...CCIC["zh"],
    ...Finallnspection["zh"],
    ...Stringer["zh"],
    ...IndustrySuite["zh"],
    ...PowerForecasting["zh"],
    ...ModelTraining["zh"],
    ...AIPlatform["zh"],
    ...Solution["zh"],
  },
  en: {
    ...Router["en"],
    ...elementEn,
    ...header["en"],
    ...layout["en"],
    ...footer["en"],
    ...ContactHongpu["en"],
    ...homepage["en"],
    ...Product["en"],
    ...CrossChecking["en"],
    ...ExploreHongpu["en"],
    ...JASolar["en"],
    ...JoinHongpu["en"],
    ...News["en"],
    ...Construction["en"],
    ...SerialRework["en"],
    ...Busbar["en"],
    ...Filming["en"],
    ...CCIC["en"],
    ...Finallnspection["en"],
    ...Stringer["en"],
    ...IndustrySuite["en"],
    ...PowerForecasting["en"],
    ...ModelTraining["en"],
    ...AIPlatform["en"],
    ...Solution["en"],
  },
};

export const i18n = new VueI18n({
  locale: store.getters.lang === "ZH" ? "zh" : "en", // 从localStorage中获取 默认英文
  messages: merged,
});

export default i18n;

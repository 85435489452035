import axios from "@utils/request.js";
const submit = {
  submitForm: (data) =>
    axios({
      url: "/email",
      method: "post",
      data,
    }),
  getNewsList: (params) =>
    axios({
      url: "/news",
      method: "get",
      params,
    }),
};

export default submit;

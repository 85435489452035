import Vue from "vue";
import VueRouter from "vue-router";
import { isMobile } from "@/utils/isMobile";
import isIpadSafari from "@/utils/isIpadSafari";

import i18n from "@/i18n";

import { pcRoutes } from "./pcRoutes";
import { mobileRoutes } from "./mobileRoutes";
Vue.use(VueRouter);
let routes = [];
console.log("识别码", navigator.userAgent);
// const isIpadSafari = /iPad.*Safari/.test(navigator.userAgent);
if (isMobile() || isIpadSafari) {
  console.log("移动端", isMobile());
  console.log("是否是ipad的Safari浏览器", isIpadSafari);
  routes = [...mobileRoutes];
} else {
  console.log("是否是ipad的Safari浏览器", isIpadSafari);
  console.log("PC端", isMobile());
  routes = [...pcRoutes];
}
// if (isMobile() || isIpadSafari) {
//   console.log("移动端", isMobile());
//   routes = [...mobileRoutes];
// } else {
//   console.log("PC端", isMobile());
//   routes = [...pcRoutes];
// }

const router = new VueRouter({
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  if (to.meta.title) {
    if (to.meta.title === i18n.t("router-1")) {
      document.title = i18n.t("router-23");
    } else {
      document.title = to.meta.title + " - " + i18n.t("router-24");
    }
  }
  next();
});

// 解决footer在当前页面点击出错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;

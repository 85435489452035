import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueTouch from "vue-touch";

import "@/style/reset.css";
import "@/style/text.scss";
//改变font-size
import "@/utils/rem.js";
import API from "@/api";
// css格式化文件
// import "normalize.css";
// css工程化样式统一管理
// import "./assets/layoutStyle/index.css";

import { i18n } from "@/i18n"; //国际化

Vue.use(ElementUI);
// v-touch可以是自定义名称 用于移动端事件处理的
Vue.use(VueTouch, { name: "v-touch" });
Vue.config.productionTip = false;
Vue.prototype.$api = new API();
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

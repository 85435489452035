import i18n from "@/i18n";

const Layout = () =>
  import(/* webpackChunkName: "/" */ "@views/Mobile/Layout/index.vue");
//移动端设备路由
export const mobileRoutes = [
  //页面初始化
  {
    path: "/",
    redirect: "/HomePage",
  },
  //首页
  {
    path: "/",
    component: Layout,
    meta: { title: "页面初始化手机" },
    children: [
      //首页
      {
        path: "/HomePage",
        name: "HomePage",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@views/Mobile/HomePage/HomePage.vue"
          ),
        meta: { title: i18n.t("router-1") },
      },
    ],
  },
  //解决方案
  {
    path: "/",
    component: Layout,
    meta: { title: "页面初始化" },
    children: [
      //解决方案
      {
        path: "/Solution",
        name: "Solution",
        component: () =>
          import(
            /* webpackChunkName: "Solution" */ "@views/Mobile/Solution/index.vue"
          ),
        meta: { title: i18n.t("router-2") },
      },
    ],
  },
  //产品
  {
    path: "/",
    component: Layout,
    meta: { title: "页面初始化" },
    children: [
      //产品
      {
        path: "/Product",
        name: "Product",
        component: () =>
          import(
            /* webpackChunkName: "Product" */ "@views/Mobile/Product/index.vue"
          ),
        meta: { title: i18n.t("router-3") },
      },
      //洪朴光伏组件串检套件
      {
        path: "/CrossChecking",
        name: "CrossChecking",
        component: () =>
          import(
            /* webpackChunkName: "CrossChecking" */ "@views/Mobile/Product/components/QMS/CrossChecking.vue"
          ),
        meta: { title: i18n.t("router-4") },
      },

      //洪朴光伏组件串返修套件
      {
        path: "/SerialRework",
        name: "SerialRework",
        component: () =>
          import(
            /* webpackChunkName: "SerialRework" */ "@views/Mobile/Product/components/QMS/SerialRework.vue"
          ),
        meta: { title: i18n.t("router-5") },
      },

      //洪朴光伏组件叠焊汇流条检套件
      {
        path: "/Busbar",
        name: "Busbar",
        component: () =>
          import(
            /* webpackChunkName: "Busbar" */ "@views/Mobile/Product/components/QMS/Busbar.vue"
          ),
        meta: { title: i18n.t("router-6") },
      },

      //洪朴光伏组件铺膜层前检套件
      {
        path: "/Filming",
        name: "Filming",
        component: () =>
          import(
            /* webpackChunkName: "Filming" */ "@views/Mobile/Product/components/QMS/Filming.vue"
          ),
        meta: { title: i18n.t("router-7") },
      },

      //洪朴光伏组件层中检套件
      {
        path: "/CCIC",
        name: "CCIC",
        component: () =>
          import(
            /* webpackChunkName: "CCIC" */ "@views/Mobile/Product/components/QMS/CCIC.vue"
          ),
        meta: { title: i18n.t("router-8") },
      },

      //洪朴光伏组件终检套件
      {
        path: "/FinalInspection",
        name: "FinalInspection",
        component: () =>
          import(
            /* webpackChunkName: "FinalInspection" */ "@views/Mobile/Product/components/QMS/FinalInspection.vue"
          ),
        meta: { title: i18n.t("router-9") },
      },

      //洪朴串焊机设备哨兵
      {
        path: "/Stringer",
        name: "Stringer",
        component: () =>
          import(
            /* webpackChunkName: "Stringer" */ "@views/Mobile/Product/components/EIS/Stringer.vue"
          ),
        meta: { title: i18n.t("router-10") },
      },

      //洪朴功率预测分析系统
      {
        path: "/PowerForecasting",
        name: "PowerForecasting",
        component: () =>
          import(
            /* webpackChunkName: "PowerForecasting" */ "@views/Mobile/Product/components/IDA/PowerForecasting.vue"
          ),
        meta: { title: i18n.t("router-11") },
      },

      //洪朴光伏组件行业套件
      {
        path: "/IndustrySuite",
        name: "IndustrySuite",
        component: () =>
          import(
            /* webpackChunkName: "IndustrySuite" */ "@views/Mobile/Product/components/IDA/IndustrySuite.vue"
          ),
        meta: { title: i18n.t("router-12") },
      },

      //洪朴智造模型训练一体机
      {
        path: "/ModelTraining",
        name: "ModelTraining",
        component: () =>
          import(
            /* webpackChunkName: "ModelTraining" */ "@views/Mobile/Product/components/CABITS/ModelTraining.vue"
          ),
        meta: { title: i18n.t("router-13") },
      },

      //洪朴智造AI平台
      {
        path: "/AIPlatform",
        name: "AIPlatform",
        component: () =>
          import(
            /* webpackChunkName: "AIPlatform" */ "@views/Mobile/Product/components/CABITS/AIPlatform.vue"
          ),
        meta: { title: i18n.t("router-14") },
      },
    ],
  },
  // 成功案例
  {
    path: "/",
    component: Layout,
    meta: { title: "页面初始化" },
    children: [
      //成功案例
      {
        path: "/SuccessStories",
        name: "SuccessStories",
        redirect: { name: "JASolar" },
        component: () =>
          import(
            /* webpackChunkName: "SuccessStories" */ "@views/Mobile/SuccessStories/index.vue"
          ),
        meta: { title: i18n.t("router-15") },
        children: [
          //东台晶澳
          {
            path: "JASolar",
            name: "JASolar",
            component: () =>
              import(
                /* webpackChunkName: "JASolar" */ "@views/Mobile/SuccessStories/JASolar.vue"
              ),
            meta: { title: i18n.t("router-16") },
          },
        ],
      },
    ],
  },
  //生态建设
  {
    path: "/",
    component: Layout,
    meta: { title: "页面初始化" },
    children: [
      //洪朴生态+
      {
        path: "/Construction",
        name: "Construction",
        component: () =>
          import(
            /* webpackChunkName: "Construction" */ "@views/Mobile/Construction/index.vue"
          ),
        meta: { title: i18n.t("router-17") },
      },
    ],
  },
  //关于洪朴
  {
    path: "/",
    component: Layout,
    meta: { title: "页面初始化" },
    children: [
      //关于洪朴
      {
        path: "/AboutHongpu",
        name: "AboutHongpu",
        redirect: { name: "ExploreHongpu" },
        component: () =>
          import(
            /* webpackChunkName: "AboutHongpu" */ "@views/Mobile/AboutHongPu/index.vue"
          ),
        meta: { title: i18n.t("router-18") },
        children: [
          // 探索洪朴
          {
            path: "ExploreHongpu",
            name: "ExploreHongpu",
            component: () =>
              import(
                /* webpackChunkName: "ExploreHongpu" */ "@views/Mobile/AboutHongPu/components/ExploreHongpu.vue"
              ),
            meta: { title: i18n.t("router-19") },
          },
          // 加入洪朴
          {
            path: "JoinHongpu",
            name: "JoinHongpu",
            component: () =>
              import(
                /* webpackChunkName: "JoinHongpu" */ "@views/Mobile/AboutHongPu/components/JoinHongpu.vue"
              ),
            meta: { title: i18n.t("router-20") },
          },
          // 联系洪朴
          {
            path: "ContactHongpu",
            name: "ContactHongpu",
            component: () =>
              import(
                /* webpackChunkName: "ContactHongpu" */ "@views/Mobile/AboutHongPu/components/ContactHongpu.vue"
              ),
            meta: { title: i18n.t("router-21") },
          },
          // 新闻资讯
          {
            path: "News",
            name: "News",
            component: () =>
              import(
                /* webpackChunkName: "News" */ "@views/Mobile/AboutHongPu/components/News.vue"
              ),
            meta: { title: i18n.t("router-22") },
          },
        ],
      },
    ],
  },
];
